import React from "react";
import { Button, Box } from "@chakra-ui/react";

const ButtonAnimated = ({ text, ...rest }) => (
  <Button role="group" {...rest}>
    <Box
      p="12.5px 40px"
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      transition="transform 0.3s, opacity 0.3s"
      transitionTimingFunction="cubic-bezier(0.75, 0, 0.125, 1)"
      _groupHover={{
        opacity: 0,
        transform: "translate3d(0, 100%, 0)",
      }}
    >
      {text}
    </Box>
    <Box
      opacity={0}
      transform="translate3d(0, -10px, 0)"
      transition="transform 0.3s, opacity 0.3s"
      transitionTimingFunction="cubic-bezier(0.75, 0, 0.125, 1)"
      _groupHover={{
        opacity: 1,
        transform: "translate3d(0, 0, 0)",
        transitionDelay: "0.045s",
      }}
    >
      {text}
    </Box>
  </Button>
);

export default ButtonAnimated;
