import React from "react";
import {
  Box,
  chakra,
  Flex,
  Heading,
  Image,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Trans, useTranslation } from "next-i18next";
import getConfig from "next/config";
import { CheckCircle } from "react-feather";
import { useTranslation as useTranslationi18n } from "react-i18next";

import SadBox from "@/components/common/SadBox";
import checkIfFullFridge from "@/utils/fullFridge";
import TransSpanComponent from "@/components/common/Trans";
import ButtonAnimated from "@/components/common/ButtonAnimated";
import Link from "@/components/common/Link";

const { publicRuntimeConfig } = getConfig();

const Mobile = (): React.ReactElement => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  const { publicRuntimeConfig } = getConfig();
  const { t } = useTranslation();
  const { i18n } = useTranslationi18n();

  return (
    <>
      <Box
        backgroundImage={`url(${publicRuntimeConfig.basePath}/bg-white.png)`}
        backgroundRepeat="no-repeat"
        backgroundSize={{ base: "75% auto", sm: "50% auto" }}
        backgroundPosition={{
          base: "calc(100% + 50px) 150px",
          sm: "calc(100% + 100px) 150px",
          md: "calc(100% + 100px) -75px",
        }}
        p={{ base: "30px 15px", sm: "50px", xl: "100px" }}
      >
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: "30px", sm: "40px" }}
          mb={{ base: "30px", sm: "50px", xl: "100px" }}
          alignItems="center"
        >
          <Box>
            {!checkIfFullFridge() ? (
              <Image
                src={`${publicRuntimeConfig.basePath}/image.png`}
                alt=""
                w="100%"
                maxW={{ base: "none", sm: "500px", md: "none" }}
                mx="auto"
              />
            ) : (
              <SadBox full />
            )}
          </Box>
          <Box>
            <Heading
              size="lg"
              variant="simple"
              fontSize={{ base: "23px", sm: "35px" }}
            >
              {checkIfFullFridge() ? t("mobile.FFtitle") : t("mobile.GStitle")}
            </Heading>
            <Text>
              {checkIfFullFridge() ? (
                <TransSpanComponent i18nKey="mobile.FFdesc1" />
              ) : (
                <Trans
                  i18nKey="mobile.GSdesc"
                  components={{
                    span: <chakra.span whiteSpace="nowrap"></chakra.span>,
                  }}
                ></Trans>
              )}
            </Text>
            {checkIfFullFridge() && (
              <Text mt="20px">
                <TransSpanComponent i18nKey="mobile.FFdesc2" />
              </Text>
            )}
            {!checkIfFullFridge() && (
              <Flex justify="center" mt="20px">
                <Link to={"/subscription"}>
                  <ButtonAnimated text={t("mobile.manageSubscription")} />
                </Link>
              </Flex>
            )}
          </Box>
        </SimpleGrid>
        <Heading
          size="lg"
          variant="blue"
          fontSize={{ base: "23px", sm: "35px" }}
        >
          {checkIfFullFridge() ? (
            <TransSpanComponent i18nKey="mobile.FFappTitle" />
          ) : (
            <TransSpanComponent i18nKey="mobile.GSappTitle" />
          )}
        </Heading>
        <SimpleGrid
          columns={{ base: 1, sm: 2 }}
          spacing={{ base: "30px", sm: "40px" }}
        >
          <Flex justify={{ base: "center", sm: "flex-end" }}>
            <Box w="210px">
              <a
                href={
                  !checkIfFullFridge()
                    ? "https://garazowewyprzedaze.pl/download/android"
                    : "https://fullfridge.app/download/android"
                }
                rel="noopener"
              >
                <Image
                  src={
                    i18n?.language == "pl"
                      ? `${publicRuntimeConfig.basePath}/google-play.png`
                      : `${publicRuntimeConfig.basePath}/google-play-en.png`
                  }
                  alt=""
                  d="block"
                  mx="auto"
                  w={{ base: "150px", sm: "205px" }}
                />
              </a>
              <Image
                src={`${publicRuntimeConfig.basePath}/${
                  checkIfFullFridge() ? "ff" : "gs"
                }_qr_android.svg`}
                alt=""
                w={{ base: "160px", sm: "100%" }}
                d="block"
                mx="auto"
                mt="27.5px"
              />
            </Box>
          </Flex>
          <Flex justify={{ base: "center", sm: "flex-start" }}>
            <Box w="210px">
              <a
                href={
                  !checkIfFullFridge()
                    ? "https://garazowewyprzedaze.pl/download/ios"
                    : "https://fullfridge.app/download/ios"
                }
                rel="noopener"
              >
                <Image
                  src={
                    i18n?.language == "pl"
                      ? `${publicRuntimeConfig.basePath}/app-store.png`
                      : `${publicRuntimeConfig.basePath}/app-store-en.png`
                  }
                  alt=""
                  d="block"
                  mx="auto"
                  w={{ base: "150px", sm: "205px" }}
                />
              </a>
              <Image
                src={`${publicRuntimeConfig.basePath}/${
                  checkIfFullFridge() ? "ff" : "gs"
                }_qr_ios.svg`}
                alt=""
                w={{ base: "160px", sm: "100%" }}
                d="block"
                mx="auto"
                mt="20px"
              />
            </Box>
          </Flex>
        </SimpleGrid>
        {!checkIfFullFridge() && (
          <>
            <Heading
              size="md"
              textTransform="none"
              textAlign="center"
              variant="gray"
              fontSize={{ base: "18px", sm: "20px" }}
            >
              {t("mobile.freeTrial")}
            </Heading>
            <Heading
              size="md"
              textTransform="none"
              textAlign="center"
              variant="gray"
              fontSize={{ base: "18px", sm: "20px" }}
            >
              {t("mobile.freeTrialGarage")}
            </Heading>
            <Flex justify="center" mt="20px">
              <Link to={"/subscription"}>
                <ButtonAnimated text={t("mobile.goToPanel")} />
              </Link>
            </Flex>
          </>
        )}
      </Box>
      {!checkIfFullFridge() && (
        <Box
          p={{ base: "30px 15px", sm: "50px", xl: "100px" }}
          color="white"
          textAlign="center"
          bg="linear-gradient(45deg, #2980b9 0%, #20638f 100%)"
        >
          <Heading
            size="lg"
            variant="white"
            fontSize={{ base: "23px", sm: "35px" }}
          >
            {t("mobile.GSiconsTitle")}
          </Heading>
          <Flex m={{ base: "-15px", sm: "-20px" }} wrap="wrap" justify="center">
            <Box
              p={{ base: "15px", sm: "20px" }}
              w={{ base: "100%", sm: "50%", md: "33.33333%" }}
            >
              <Image
                src={`${publicRuntimeConfig.basePath}/ico2.png`}
                alt=""
                w={{ base: "120px", sm: "150px" }}
                h={{ base: "120px", sm: "150px" }}
                d="block"
                mx="auto"
              />
              <Heading
                size="md"
                color="blue.100"
                fontSize={{ base: "18px", sm: "20px" }}
              >
                {t("mobile.GSiconsTitle1")}
              </Heading>
              <TransSpanComponent i18nKey="mobile.GSiconsDesc1" />
            </Box>
            <Box
              p={{ base: "15px", sm: "20px" }}
              w={{ base: "100%", sm: "50%", md: "33.33333%" }}
            >
              <Image
                src={`${publicRuntimeConfig.basePath}/ico4.png`}
                alt=""
                w="150px"
                h="150px"
                d="block"
                mx="auto"
              />
              <Heading
                size="md"
                color="blue.100"
                fontSize={{ base: "18px", sm: "20px" }}
              >
                {t("mobile.GSiconsTitle2")}
              </Heading>
              <Text>{t("mobile.GSiconsDesc2")}</Text>
            </Box>
            <Box
              p={{ base: "15px", sm: "20px" }}
              w={{ base: "100%", sm: "50%", md: "33.33333%" }}
            >
              <Image
                src={`${publicRuntimeConfig.basePath}/ico1.png`}
                alt=""
                w={{ base: "120px", sm: "150px" }}
                h={{ base: "120px", sm: "150px" }}
                d="block"
                mx="auto"
              />
              <Heading
                size="md"
                color="blue.100"
                fontSize={{ base: "18px", sm: "20px" }}
              >
                {t("mobile.GSiconsTitle3")}
              </Heading>
              <Text>{t("mobile.GSiconsDesc3")}</Text>
            </Box>
            <Box
              p={{ base: "15px", sm: "20px" }}
              w={{ base: "100%", sm: "50%", md: "33.33333%" }}
            >
              <Image
                src={`${publicRuntimeConfig.basePath}/ico5.png`}
                alt=""
                w={{ base: "120px", sm: "150px" }}
                h={{ base: "120px", sm: "150px" }}
                d="block"
                mx="auto"
              />
              <Heading
                size="md"
                color="blue.100"
                fontSize={{ base: "18px", sm: "20px" }}
              >
                {t("mobile.GSiconsTitle4")}
              </Heading>
              <TransSpanComponent i18nKey="mobile.GSiconsDesc4" />
            </Box>
            <Box
              p={{ base: "15px", sm: "20px" }}
              w={{ base: "100%", sm: "50%", md: "33.33333%" }}
            >
              <Image
                src={`${publicRuntimeConfig.basePath}/ico6.png`}
                alt=""
                w={{ base: "120px", sm: "150px" }}
                h={{ base: "120px", sm: "150px" }}
                d="block"
                mx="auto"
              />
              <Heading
                size="md"
                color="blue.100"
                fontSize={{ base: "18px", sm: "20px" }}
              >
                {t("mobile.GSiconsTitle5")}
              </Heading>
              <TransSpanComponent i18nKey="mobile.GSiconsDesc5" />
            </Box>
          </Flex>
        </Box>
      )}
      <Box
        p={{ base: "30px 15px", sm: "50px", xl: "100px" }}
        mt={
          checkIfFullFridge() ? { base: "-30px", sm: "-50px", xl: "-100px" } : 0
        }
      >
        {!checkIfFullFridge() && (
          <Heading
            size="lg"
            variant="blue"
            fontSize={{ base: "23px", sm: "35px" }}
          >
            <TransSpanComponent i18nKey="mobile.GSmapTitle" />
          </Heading>
        )}
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: "30px", sm: "40px" }}
          alignItems="center"
        >
          <Box>
            <Image
              src={`${publicRuntimeConfig.basePath}/map.png`}
              alt=""
              w="100%"
              maxW={{ base: "none", sm: "500px", md: "none" }}
              mx="auto"
            />
          </Box>
          <Box textAlign="justify">
            <List spacing="20px">
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.500"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>
                  <TransSpanComponent i18nKey="mobile.GSmapDesc1" />
                </Box>
              </ListItem>
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.500"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>
                  {checkIfFullFridge() ? (
                    <TransSpanComponent i18nKey="mobile.FFmapDesc2" />
                  ) : (
                    t("mobile.GSmapDesc2")
                  )}
                </Box>
              </ListItem>
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.500"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>
                  {checkIfFullFridge() ? (
                    <TransSpanComponent i18nKey="mobile.FFmapDesc3" />
                  ) : (
                    t("mobile.GSmapDesc3")
                  )}
                </Box>
              </ListItem>
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.500"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>
                  {checkIfFullFridge() ? (
                    t("mobile.FFmapDesc4")
                  ) : (
                    <TransSpanComponent i18nKey="mobile.GSmapDesc4" />
                  )}
                </Box>
              </ListItem>
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.500"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>
                  {checkIfFullFridge() ? (
                    <TransSpanComponent i18nKey="mobile.FFmapDesc5" />
                  ) : (
                    <TransSpanComponent i18nKey="mobile.GSmapDesc5" />
                  )}
                </Box>
              </ListItem>
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.500"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>
                  {checkIfFullFridge() ? (
                    <TransSpanComponent i18nKey="mobile.FFmapDesc6" />
                  ) : (
                    <TransSpanComponent i18nKey="mobile.GSmapDesc6" />
                  )}
                </Box>
              </ListItem>
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.500"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>
                  {checkIfFullFridge() ? (
                    t("mobile.FFmapDesc7")
                  ) : (
                    <TransSpanComponent i18nKey="mobile.FFmapDesc7" />
                  )}
                </Box>
              </ListItem>
              {!checkIfFullFridge() && (
                <ListItem d="flex">
                  <ListIcon
                    as={CheckCircle}
                    color="green.500"
                    w="20px"
                    h="20px"
                    flexShrink={0}
                    mt="3px"
                  />
                  <Box flex={1}>
                    <TransSpanComponent i18nKey="mobile.GSmapDesc8" />
                  </Box>
                </ListItem>
              )}
            </List>
          </Box>
        </SimpleGrid>
      </Box>
      <Box
        p={{ base: "30px 15px", sm: "50px", xl: "100px" }}
        color="white"
        bg="linear-gradient(45deg, #27ae60 0%, #1e8449 100%)"
      >
        <Heading
          size="lg"
          variant="white"
          fontSize={{ base: "23px", sm: "35px" }}
        >
          {checkIfFullFridge() ? (
            t("mobile.FFfridgeTitle")
          ) : (
            <TransSpanComponent i18nKey="mobile.GSfridgeTitle" />
          )}
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: "30px", sm: "40px" }}
          alignItems="center"
        >
          <Box textAlign="justify">
            <List spacing="20px">
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.100"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>
                  {checkIfFullFridge() ? (
                    <TransSpanComponent i18nKey="mobile.FFfridgeDesc1" />
                  ) : (
                    <TransSpanComponent i18nKey="mobile.GSfridgeDesc1" />
                  )}
                </Box>
              </ListItem>
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.100"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>
                  {checkIfFullFridge()
                    ? t("mobile.FFfridgeDesc2")
                    : t("mobile.GSfridgeDesc2")}
                </Box>
              </ListItem>
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.100"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>
                  {checkIfFullFridge()
                    ? t("mobile.FFfridgeDesc3")
                    : t("mobile.GSfridgeDesc3")}
                </Box>
              </ListItem>
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.100"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>
                  <TransSpanComponent i18nKey="mobile.GSfridgeDesc4" />
                </Box>
              </ListItem>
              <ListItem d="flex">
                <ListIcon
                  as={CheckCircle}
                  color="green.100"
                  w="20px"
                  h="20px"
                  flexShrink={0}
                  mt="3px"
                />
                <Box flex={1}>{t("mobile.GSfridgeDesc5")}</Box>
              </ListItem>
            </List>
            <Text mt="20px">
              <TransSpanComponent i18nKey="mobile.GSfridgeDesc6" />
            </Text>
          </Box>
          <Box>
            <Image
              src={`${publicRuntimeConfig.basePath}/fridge2.png`}
              alt=""
              w="auto"
              maxH="330px"
              mx="auto"
            />
          </Box>
        </SimpleGrid>
      </Box>
      {/*<Box
        p={{ base: "30px 15px", sm: "50px", xl: "100px"}}
        color="white"
        bg="linear-gradient(45deg, #2980b9 0%, #20638f 100%)"
      >
        <Heading size="lg" variant="white" fontSize={{ base: "23px", sm: "35px" }}>
          O co zwykle pytacie?
        </Heading>
        <Accordion>
          <AccordionItem>
            <AccordionButton justifyContent="space-between">
              <Heading size="md" variant="white" textTransform="none" m={0} fontSize={{ base: "18px", sm: "20px" }}>
                Ile kosztuje?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="blue.100">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Text>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton justifyContent="space-between">
              <Heading size="md" variant="white" textTransform="none" m={0} fontSize={{ base: "18px", sm: "20px" }}>
                Ile kosztuje?
              </Heading>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text color="blue.100">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Text>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Box>*/}
    </>
  );
};

export default Mobile;
