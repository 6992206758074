import { chakra } from "@chakra-ui/react";
import { Trans } from "next-i18next";

const TransSpanComponent = ({ ...props }) => {
  return (
    <Trans
      {...props}
      components={{ span: <chakra.span whiteSpace="nowrap"></chakra.span> }}
    ></Trans>
  );
};

export default TransSpanComponent;
